/**
 * DashboardLayout component that wraps the entire dashboard with a header, footer, and a spinning loader.
 *
 * @description This component uses the `ErrorBoundary` component to catch and handle any errors that occur within the dashboard.
 * It also uses the `Context` from the `ContextProvider` to get the `fullPageLoading` state, which determines whether to display the spinning loader.
 *
 */
import { Outlet, useNavigate } from "react-router-dom";
import "./DashboardLayout.scss";
import Header from "../../Header/Header";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import React, { useState } from "react";
import { Layout } from "antd";
import Logo from "../../../assets/Logo.svg";
import Path from "../../../Routing/Constant/RoutePaths.jsx";
import HeaderMenu from "../../Header/Menu/HeaderMenu";
import { Grid } from "antd";
import { ButtonCustom } from "../../ButtonCustom/ButtonCustom.jsx";
import { LogOut } from "../../../assets/StoreAsset/StoreAsset.jsx";
import LogOutModal from "./LogOutModal.jsx";
import FooterBar from "./FooterBar.jsx";
import { handleLogout } from "../../Header/header.helper.jsx";

const { Sider, Content } = Layout;
function DashboardLayout() {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleOk = async () => {
    setLoading(true);
    const res = await handleLogout();
    setLoading(false);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ErrorBoundary>
      <div className="dashLayout ">
        <Layout>
          {xl ? (
            <Sider trigger={null} collapsible collapsed={collapsed} width={280}>
              <div className="demo-logo-vertical">
                <img
                  src={Logo}
                  alt="Logo"
                  onClick={() => navigate(Path?.HOME)}
                />
              </div>
              <HeaderMenu />
              {localStorage.getItem("isLogged") ? (
                <ButtonCustom
                  onClick={showModal}
                  className="log_outBtn"
                  leftIcon={<LogOut />}
                  label="Log Out"
                />
              ) : null}

              <LogOutModal
                open={isModalOpen}
                isLoading={loading}
                onOk={handleOk}
                onclickCancel={handleCancel}
              />
            </Sider>
          ) : null}
          <Layout>
            <Header />
            <Content>
              <div className="dashLayout_container">
                <div className="dashLayout_body ">
                  <Outlet />
                </div>
              </div>
            </Content>
            <FooterBar />
          </Layout>
        </Layout>
      </div>
    </ErrorBoundary>
  );
}

export default DashboardLayout;
