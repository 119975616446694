import moment from "moment";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import ProfileImg from "../../assets/BlueLogo.svg";
import {
  fixedDecimals,
  formatNumber,
  globalTimeFormat,
  showTime,
  timeStampToDate,
} from "../../utils/helpers/commonHelper";
import { env, msgs } from "../../utils/appConstants";
import { useEventCards } from "../../Hooks/useEventCards";
import { betOn } from "../Marketplace/marketPlace.helper";
import { Context } from "../ContextProvider/ContextProvider";
import { getClassName, showUserName } from "./recentActivity.helper";
import RandomImageGenerator from "../../Common/ImageGenerator";
import { Flex } from "antd";
import CommonConfirmationModal from "../../Common/CommonConfirmModal/CommonConfirmModal";

/**
 * @dev RecentActivityCard component displays a list of recent activities in a card format.
 *  - Component props
 * @param {Array} props.data - Array of activity data to be displayed
 * @returns {JSX.Element} - Rendered component
 */
const RecentActivityCard = ({ data }) => {
  // Custom hook to handle recent activity logic
  const { handleBetOn, show, handleCancel, handleYes, isLoading } =
    useEventCards();
  const { coinUrl } = useContext(Context);
  const { pathname } = useLocation();

  const limitedData = Array.isArray(data) ? data.slice(0, 2) : [];

  return (
    <div className="recentActivitycard">
      {limitedData?.length
        ? limitedData?.map((val, index) => {
            // Determine the icon URL or fallback to a default image
            const url = Object.keys(coinUrl)?.length
              ? coinUrl[val?.currencyType]
              : ProfileImg;
            return (
              <div
                key={index}
                className="cardOuter"
                data-testid="recent-activity-card"
                onClick={() =>
                  handleBetOn({
                    betOn: "yes",
                    eventId: val?.eventId,
                  })
                }
              >
                <div className="topCard">
                  <div className="currentPrice">
                    <div className="inerDetail">
                      <div className="imageData">
                        <img
                          src={url}
                          alt={val?.currencyType}
                          className="coin-icons"
                        />
                      </div>
                      <h4>
                        {val?.currencyType || "-"} {msgs.toBePriceAt}{" "}
                        {val?.priceLevel
                          ? fixedDecimals(
                              val?.priceLevel,
                              env.currentpricePrecision
                            )
                          : 0}{" "}
                        {msgs.usdtOrMore}{" "}
                        {globalTimeFormat(
                          moment(timeStampToDate(val?.targetDateTime))
                        )}
                        ?
                      </h4>
                    </div>
                    <Flex
                      justify="space-between"
                      align="center"
                      wrap
                      className="showTime"
                    >
                      <h2>
                        <span className="marketTd">
                          <RandomImageGenerator
                            data={val?.userDetails?.profilePicture?.small}
                            className="coin-icons"
                            alt="profile-img"
                          />
                        </span>
                        <span>
                          {showUserName(
                            val?.userDetails?.userName
                              ? val?.userDetails?.userName
                              : val?.userId
                          )}
                          {val?.bidType === "false" || val?.bidType === "true"
                            ? "bought"
                            : ""}
                          <span
                            className={`bidDecision ${getClassName(
                              val?.bidType
                            )}`}
                          >
                            <span
                              className={betOn
                                ?.activity(pathname)
                                [val?.bidType]?.toLowerCase()}
                            >
                              {betOn?.activity(pathname)[val?.bidType || "-"]}
                            </span>
                          </span>
                          {betOn?.activity(pathname)[val?.bidType] ===
                            "Withdrew" ||
                          betOn?.activity(pathname)[val?.bidType] === "Claimed"
                            ? ""
                            : "for"}{" "}
                          <span className="priceVal">
                            {Number(formatNumber(val?.currentBet)).toFixed(
                              env?.precision
                            )}{" "}
                            {env?.currency}
                          </span>
                        </span>
                      </h2>
                      <p className="duration">{showTime(val?.updatedAt)}</p>
                    </Flex>
                  </div>
                </div>
                <CommonConfirmationModal
                  closable={false}
                  desc={msgs.connectionRequest}
                  handleCancel={handleCancel}
                  handleYes={handleYes}
                  header={msgs.connect}
                  loading={isLoading}
                  show={show}
                />
              </div>
            );
          })
        : null}
    </div>
  );
};

export default RecentActivityCard;
