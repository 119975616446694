/**
 * HeaderLeft component that renders the logo, search bar, and menu for large screens.
 *
 * @returns {JSX.Element} The HeaderLeft component.
 *
 */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { msgs } from "../../utils/appConstants";
import CustomSearch from "../CustomSearch/CustomSearch";
import useHeaderSearch from "../../Hooks/useHeaderSearch";

const HeaderLeft = () => {
  const { pathname } = useLocation();

  const [show, setShow] = useState(null);
  const { setSearch, search } = useHeaderSearch({
    pathname,
    setShow,
  });

  return (
    <div className="headerlogo">
      <div className="headerLeft">
        <CustomSearch
          value={search}
          placeholder={msgs.searchMarket}
          handleOnChange={setSearch}
          mainClassLayout={!show ? "hide-search" : null}
        />
      </div>
    </div>
  );
};

export default HeaderLeft;
