/**
 * Activity component that displays a table of activity data.
 *
 * This component fetches activity data and metadata using the `useGetActivity` hook,
 * and manages table pagination using the `useTablePagination` hook.
 * It also scrolls to the top of the page when the component mounts using the `useScrollToTop` hook.
 *
 */
import { Skeleton, Table } from "antd";
import React from "react";
import "./Activity.scss";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { useGetActivity } from "../../Hooks/useGetActivity";
import PaginationSelect from "../../Common/PaginationSelect/PaginationSelect";
import CommonConfirmationModal from "../../Common/CommonConfirmModal/CommonConfirmModal";
import { msgs } from "../../utils/appConstants";

function Activity() {
  // Scroll to the top of the page when the component mounts
  useScrollToTop();
  // Fetch activity data and metadata
  const {
    metaData,
    columns,
    onRowActivity,
    handleCancel,
    handleYes,
    isLoading,
    show,
    handleTableChange,
    tableParams,
  } = useGetActivity();

  return (
    <>
      <div className="filter"></div>
      <div
        className={`activityTable ${
          metaData?.data?.length ? "show-cursor" : ""
        }`}
      >
        <div className="container">
          {metaData?.loading ? (
            <div className="no-events-found" data-testid="skeleton-container">
              <Skeleton active paragraph={{ rows: 5 }} />
            </div>
          ) : (
            <Table
              columns={columns}
              onRow={onRowActivity}
              dataSource={metaData?.data}
              pagination={{
                selectComponentClass: PaginationSelect,
                hideOnSinglePage: metaData?.total <= 10 ? true : false,
                ...tableParams?.pagination,
              }}
              onChange={handleTableChange}
              className="commonTable"
            />
          )}
        </div>
        <CommonConfirmationModal
          closable={false}
          desc={msgs.connectionRequest}
          handleCancel={handleCancel}
          handleYes={handleYes}
          header={msgs.connect}
          loading={isLoading}
          show={show}
        />
      </div>
    </>
  );
}

export default Activity;
