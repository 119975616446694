import Select from "antd/lib/select";
import React from "react";

const PaginationSelect = (props) => {
  return (
    <Select {...props} size="small" className="antd-pagination-no-search" />
  );
};
PaginationSelect.Option = Select.Option;

export default PaginationSelect;
