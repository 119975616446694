import React from "react";
import { Input } from "antd";
import { Field } from "formik";
import FormikError from "../FormikError/FormikError";

const CustomTextBox = ({ max, name, ...rest }) => {
  const { TextArea } = Input;

  return (
    <Field name={name}>
      {({ field, form: { touched, values, errors, setFieldValue } }) => {
        const nonSpaceCharacterCount = (values[name] || "").replace(
          /\s/g,
          ""
        ).length;

        const handleChange = (e) => {
          const { value } = e.target;
          // Update the value in Formik state
          setFieldValue(name, value);
        };

        return (
          <>
            <TextArea
              {...rest}
              {...field}
              rows={5}
              id={name}
              maxLength={max}
              onChange={handleChange}
            />
            {touched[name] && errors[name] ? (
              <FormikError>{errors[name]}</FormikError>
            ) : null}
            <div className="count">{`${nonSpaceCharacterCount} / ${max}`}</div>
          </>
        );
      }}
    </Field>
  );
};

export default CustomTextBox;
