/* istanbul ignore next */

/**
 * HeaderMenu component
 *
 * A React component that renders a menu in the header section of the application.
 *
 * @param {string} className - An optional class name to be added to the container element.
 *
 * @returns {React.ReactElement} A React element representing the header menu.
 *
 */
import React, { useContext } from "react";
import "../Header.scss";
import CustomList from "../../CustomList/CustomList";
import { headerMenuData } from "../../../utils/appConstants";
import { Context } from "../../../Pages/ContextProvider/ContextProvider";

function HeaderMenu({ toggleMenu }) {
  const { setSearchHome, setSearch, setFilter } = useContext(Context);

  return (
    <div className={`headerLink`}>
      <CustomList
        list={headerMenuData()}
        liClassName="headerLink_menu_item"
        ulClassName="headerLink_menu"
        linkClasName="active"
        opentoNewTab={false}
        onClick={() => {
          toggleMenu && toggleMenu();
          setSearchHome(null);
          setSearch(null);
          setFilter("volume");
        }}
      />
    </div>
  );
}

export default HeaderMenu;
